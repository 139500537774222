import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

import history from '~/services/history';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      opacity: '0.9',
      margin: 'auto',
    },
  },
}));

// Define o número de páginas
function definePagesNumber(results, maxResults) {
  results /= maxResults;
  if (results % 1 !== 0) results = Math.trunc(results) + 1;
  return results;
}

export default function PaginationControlled({ count, maxResults, page }) {
  const classes = useStyles();
  const handleChange = (event, value) => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', value);
    history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
  };

  return (
    <div className={classes.root}>
      <Pagination
        color="primary"
        count={definePagesNumber(count, maxResults)}
        page={page || 1}
        onChange={handleChange}
      />
    </div>
  );
}

PaginationControlled.propTypes = {
  count: PropTypes.number,
  maxResults: PropTypes.number,
  page: PropTypes.number,
};

PaginationControlled.defaultProps = {
  count: 0,
  maxResults: 0,
  page: 1,
};
