import styled from 'styled-components';

export const Container = styled.div`
  align-self: center;
  margin-bottom: 30px;
  label {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    img {
      /* display: inline; */
      margin: 0 auto;
      height: 100%;
      width: auto;
    }
    input {
      display: none;
    }
  }
  .image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    /* border: 1px solid black; */
    border-radius: 50%;
    overflow: hidden;
  }
`;
