/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';

import { activationRequest } from '~/store/modules/auth/actions';
import makeStyles from '~/styles/makeStyles';

import logo from '~/assets/splash.png';

const useStyles = makeStyles;

export default function Activation() {
  const classes = useStyles();
  const profile = useSelector((state) => state.user.profile);

  const formRef = useRef(null);
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    key: Yup.string()
      .required('A chave de ativação é obrigatória.')
      .min(5, 'A chave de ativação deve ter no mínimo 5 caracteres.'),
  });

  async function handleSubmit(data) {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const { key } = data;

      dispatch(activationRequest(profile, key));

      formRef.current.setErrors({});
      formRef.current.setFieldValue('key', '');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((e) => {
          errorMessages[e.path] = e.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  return (
    <>
      <div className="img">
        <img src={logo} alt="logo" />
      </div>
      <div className="signup">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Ativação</h1>
          <span className="infoActivation">
            <label>Nome: </label> {profile.name}
          </span>
          <span className="infoActivation">
            <label> Email: </label> {profile.email}
          </span>
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Chave de Ativação"
            name="key"
          />
          <button type="submit">Confirmar</button>
        </Form>
      </div>
    </>
  );
}
