/* eslint-disable react/jsx-no-bind */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import makeStyles from '~/styles/makeStyles';

import AvatarInput from './AvatarInput';
import { updateProfileRequest } from '~/store/modules/user/actions';
import { networkErrorMessage } from '~/constants/message';

import { Container } from './styles';

const useStyles = makeStyles;

export default function Profile() {
  const classes = useStyles();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório.'),
    email: Yup.string()
      .email('Insira um e-mail válido.')
      .required('O e-mail é obrigatório.'),
    newPassword: Yup.string().test(
      'len',
      'A senha precisa ter no mínimo 8 caracteres',
      (val) => !val || (val && val.toString().length >= 8)
    ),
    oldPassword: Yup.string().when('newPassword', {
      is: (password) => password.length > 0,
      then: Yup.string().required('Necessário digitar a senha.'),
    }),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'As senhas precisam ser iguais.'
    ),
  });

  const profile = useSelector((state) => state.user.profile);

  async function handleSubmit(data) {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(updateProfileRequest(data));

      formRef.current.setErrors({});
      formRef.current.setFieldValue('newPassword', '');
      formRef.current.setFieldValue('oldPassword', '');
      formRef.current.setFieldValue('confirmPassword', '');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((e) => {
          errorMessages[e.path] = e.message;
        });
        formRef.current.setErrors(errorMessages);
      } else toast.error(networkErrorMessage);
    }
  }

  return (
    <>
      <Container>
        <Form initialData={profile} ref={formRef} onSubmit={handleSubmit}>
          <h1>Perfil</h1>
          <AvatarInput name="avatar_id" />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Nome"
            name="name"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Email"
            name="email"
          />
          <hr />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Nova Senha"
            name="newPassword"
            type="password"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Confirmação de Senha"
            name="confirmPassword"
            type="password"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Senha Atual"
            name="oldPassword"
            type="password"
          />
          <button type="submit">Atualizar</button>
        </Form>
      </Container>
    </>
  );
}
