/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { TextField, Switch } from 'unform-material-ui';
import { toast } from 'react-toastify';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { FiArrowLeft } from 'react-icons/fi';
import goBack from '~/utils/goBack';

import history from '~/services/history';
import api from '~/services/api';
import { networkErrorMessage } from '~/constants/message';

import { CadastroUser } from './styles';
import genericProfile from '~/assets/profile.jpg';
import makeStyles from '~/styles/makeStyles';

const useStyles = makeStyles;

export default function EditUser() {
  const classes = useStyles();
  const formRef = useRef(null);
  const { id } = useParams();
  const [checked, setChecked] = React.useState(false);
  const [user, setUsers] = useState([]);

  useEffect(() => {
    async function loadUser() {
      const response = await api.get(`users/${id}`);

      formRef.current.setData({
        name: response.data.name,
        email: response.data.email,
        status: response.data.status,
        // inserir valores a serem carregados na tela
      });
      setUsers(response.data);
      setChecked(response.data.status === 'active');
    }
    loadUser();
  }, [id]);

  async function handleSubmit(data) {
    try {
      if (data.status === true) {
        data.status = 'active';
      } else {
        data.status = 'inactive';
      }

      await api.put(`users/${id}`, data);
      toast.success('Dados atualizados com sucesso!');
      history.push('/usuarios');
    } catch (error) {
      toast.error(networkErrorMessage);
    }
  }

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <CadastroUser>
      <FiArrowLeft className="icon" onClick={goBack} size={30} />

      <div className="bodyRegister">
        <h1>Usuário</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <img
            className="imgUser"
            src={user.avatar ? user.avatar.url : genericProfile}
            alt="logoBody"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Nome"
            name="name"
            disabled
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Email"
            name="email"
            disabled
          />

          <div className="switch">
            <p>Status</p>
            <FormControlLabel
              control={
                <Switch
                  name="status"
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                />
              }
            />
          </div>
          <button type="submit">Atualizar</button>
        </Form>
      </div>
    </CadastroUser>
  );
}
