import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import api from '~/services/api';
import Loading from '~/components/Loading';
import { networkErrorMessage } from '~/constants/message';

import genericProfile from '~/assets/profile.jpg';
import { ListAcesso } from './styles';

export default function Dashboard() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);

  const [report, setReport] = useState({
    records: [],
    lastAccess: [],
    count: 0,
    unidade: '',
  });

  // Determina quantos registros a API deve retornar
  const limit = 10;

  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    // Faz a requisição com parâmetros de pesquisa e paginação
    async function loadRecords() {
      try {
        setLoading(true);
        if (profile.role === 'householder' || profile.role === 'dweller') {
          const response = await api.get(
            `accessreport${location.search}${
              location.search ? '&' : '?'
            }limit=${limit}&type=self`
          );
          setReport(response.data);
        }
        if (profile.role === 'admin' || profile.role === 'super') {
          const response = await api.get(`users?recent=true&limit=${limit}`);
          setUsers(response.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(networkErrorMessage);
      }
    }

    loadRecords();
  }, [location.search, profile.role]);

  function castStatus(status) {
    if (status === 'new') return 'Novo';
    if (status === 'active') return 'Ativo';
    if (status === 'inactive') return 'Inativo';
    if (status === 'blocked') return 'Bloqueado';
    return '';
  }

  function castType(type) {
    if (type === 'super') return 'Super';
    if (type === 'admin') return 'Administrador';
    if (type === 'employee') return 'Funcionário';
    if (type === 'householder') return 'Residente';
    if (type === 'dweller') return 'Residente';
    if (type === 'guest') return 'Visitante';
    return '';
  }

  function setStatusColor(status) {
    if (status === 'Liberado') return '#008000';
    if (status === 'Negado') return '#cc0000';

    return '#333';
  }

  return (
    <ListAcesso>
      <div className="header">
        <h1>Principal</h1>
      </div>
      <h2>Bem vindo(a), {profile.name}!</h2>
      {(profile.role === 'householder' || profile.role === 'dweller') && (
        <>
          <span className="titleTable">Últimos Acessos</span>
          {loading ? (
            <div className="load">
              <Loading />
            </div>
          ) : (
            <table>
              <tbody>
                <tr>
                  <td className="titleColumn">Data/Hora</td>
                  <td className="titleColumn">Local</td>
                  <td className="titleColumn">Status</td>
                  <td className="titleColumn">Direção</td>
                  <td className="titleColumn">Método de Acesso</td>
                </tr>
                {report.records.map((r) => (
                  <tr
                    key={r.id}
                    style={
                      {
                        // backgroundColor: r.granted ? '#fff' : '#fff2f2',
                      }
                    }
                  >
                    <td>{r.date}</td>
                    <td>{r.device}</td>
                    <td style={{ color: setStatusColor(r.status) }}>
                      {r.status}
                    </td>
                    <td>{r.direction}</td>
                    <td>{r.method}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
      {(profile.role === 'admin' ||
        profile.role === 'employee' ||
        profile.role === 'super') && (
        <>
          <span className="titleTable">Cadastros Recentes</span>
          {loading ? (
            <div className="load">
              <Loading />
            </div>
          ) : (
            <table>
              <tbody>
                <tr>
                  <td className="first">Status</td>
                  <td className="first">Nome</td>
                  <td className="first">Tipo</td>
                  <td className="first">Condomínio</td>
                </tr>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{castStatus(user.status)}</td>
                    <td className="second">
                      <div className="imgWithText">
                        <div className="image_container">
                          <img
                            className="imgPerfil"
                            src={user.avatar ? user.avatar.url : genericProfile}
                            alt="logoBody"
                          />
                        </div>
                        {user.name}
                      </div>
                    </td>
                    <td className="second">{castType(user.role)}</td>
                    <td className="second">
                      {user.condo ? user.condo.name : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </ListAcesso>
  );
}
