import styled from 'styled-components';
import search from '~/assets/search.png';

export const ListTableUser = styled.div`
  width: 100%;
  height: auto;

  .imgValidation {
    margin-right: 20px;
    width: 30px;
    border-radius: 50%;
    height: 30px;
  }

  table {
    margin-top: 0px;
    width: 100%;
    border-spacing: 0 0px;
  }

  tr {
    height: 55px;
  }

  td {
    padding-left: 20px;
    margin-top: 10px;
    text-align: left;
    font-family: 'poppins', sans-serif;
    color: #888888;
    font-size: 14px;
    vertical-align: middle;
  }

  .imgWithText {
    display: flex;
    align-items: center;
  }

  .imgPerfil {
    margin: 0 auto;
    height: 100%;
    width: auto;
  }

  .image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }

  .first {
    font-size: 16px;
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
    margin-left: 20px;
    font-size: 28px;
    font-family: 'poppins';
    font-weight: bold;
    color: #00507c;

    &:hover {
      font-size: 29px;
      opacity: 0.8;
    }
  }

  tr + tr:hover {
    background-color: #e6f2ff;
  }

  .icon-body {
    color: #00507c;
    cursor: pointer;
    margin-left: 10px;
  }

  span {
    color: #00507c;
    font-weight: bold;
  }

  .headerList {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input {
    border-radius: 100px;
    border: 1px solid #cccccc;
    width: 400px;
    height: 30px;
    margin-left: auto;
    text-align: left;
    text-indent: 10px;
    padding-right: 10px;
    font-family: 'poppins', sans-serif;
    margin-right: 20px;
    padding: 17px;
    padding-left: 35px;
    background-repeat: no-repeat;
    background-image: url(${search});
    background-position: left center;
    outline: 0;
    background-size: 5ex;
    color: black;
    &::placeholder {
      color: #858585;
    }
  }

  .pagination {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }
`;
