/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { TextField, Select, Switch } from 'unform-material-ui';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MenuItem } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { FiArrowLeft } from 'react-icons/fi';
import goBack from '~/utils/goBack';

import api from '~/services/api';
import history from '~/services/history';
import makeStyles from '~/styles/makeStyles';
import { networkErrorMessage } from '~/constants/message';

import { Container } from './styles';

const useStyles = makeStyles;

export default function CondoEdit() {
  const classes = useStyles();
  const formRef = useRef(null);
  const { id } = useParams();

  const [checked, setChecked] = React.useState(false);
  const [checkedQrResidents, setCheckedQrResidents] = React.useState(false);
  const [checkedQrGuests, setCheckedQrGuests] = React.useState(false);

  useEffect(() => {
    async function loadCondos() {
      const response = await api.get(`condos/${id}`);

      formRef.current.setData({
        name: response.data.name,
        api_url: response.data.api_url,
        status: response.data.status,
        type: response.data.type,
        instruction_first: response.data.instruction_first,
        instruction_next: response.data.instruction_next,
        allows_qr_residents: response.data.allows_qr_residents,
        allows_qr_guests: response.data.allows_qr_guests,
        address: response.data.address,
      });

      setChecked(response.data.allows_full_lib === true);
      setCheckedQrResidents(response.data.allows_qr_residents === true);
      setCheckedQrGuests(response.data.allows_qr_guests === true);
    }

    loadCondos();
  }, [id]);

  async function handleSubmit(data) {
    try {
      // eslint-disable-next-line no-console
      console.log(data);
      await api.put(`condos/${id}`, data);
      toast.success('Dados atualizados com sucesso!');
      history.push('/condominios');
    } catch (error) {
      toast.error(networkErrorMessage);
    }
  }

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleChangeQrResidents = () => {
    setCheckedQrResidents((prev) => !prev);
  };

  const handleChangeQrGuests = () => {
    setCheckedQrGuests((prev) => !prev);
  };

  return (
    <Container>
      <FiArrowLeft className="icon" onClick={goBack} size={30} />

      <div className="bodyRegister">
        <h1>Condomínio</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextField
            color="primary"
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Nome"
            name="name"
          />

          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="URL API"
            name="api_url"
          />
          <Select
            name="type"
            id="type"
            defaultValue="residential"
            className={classes.margin}
            label="Tipo"
          >
            <MenuItem value="residential">Residencial</MenuItem>
            <MenuItem value="commercial">Empresarial</MenuItem>
          </Select>

          {/* <Select
            name="status"
            id="status"
            defaultValue="online"
            className={classes.margin}
            label="Status"
          >
            <MenuItem value="online">Ativo</MenuItem>
            <MenuItem value="deactivated">Desativado</MenuItem>
          </Select> */}

          <hr />
          <div className="switch">
            <p>Permite Liberação Direta</p>
            <FormControlLabel
              control={
                <Switch
                  name="allows_full_lib"
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                />
              }
            />
          </div>

          <div className="switch">
            <p>Permite Entrada com QR para Residentes</p>
            <FormControlLabel
              control={
                <Switch
                  name="allows_qr_residents"
                  checked={checkedQrResidents}
                  onChange={handleChangeQrResidents}
                  color="primary"
                />
              }
            />
          </div>

          <div className="switch">
            <p>Permite Entrada com QR para Visitantes</p>
            <FormControlLabel
              control={
                <Switch
                  name="allows_qr_guests"
                  checked={checkedQrGuests}
                  onChange={handleChangeQrGuests}
                  color="primary"
                />
              }
            />
          </div>
          <hr />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Instruções de Solicitação de Liberação"
            name="instruction_first"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Instruções de Liberação DIRETA"
            name="instruction_next"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Endereço"
            name="address"
          />
          <hr />

          <button type="submit">Atualizar</button>
        </Form>

        {/* <h1>Informações Locais</h1>
        <table>
          <tbody>
            <tr>
              <td className="titleColumn">Versão</td>
              <td className="titleColumn">Licença</td>
              <td className="titleColumn">Notificações</td>
            </tr>
            <tr>
              <td>3.5.0</td>
              <td>17/03/2021</td>
              <td>Sim</td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </Container>
  );
}
