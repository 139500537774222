/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';
import { Form } from '@unform/web';
import { TextField } from 'unform-material-ui';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import validatePassword from '../../utils/validatePassword';

import makeStyles from '~/styles/makeStyles';
import logo from '~/assets/splash.png';

const useStyles = makeStyles;

export default function Activation() {
  const classes = useStyles();
  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    key: Yup.string().required('Informe a chave de ativação.'),
    email: Yup.string()
      .email('Insira um e-mail válido.')
      .required('Informe seu e-mail.'),
    password: Yup.string()
      .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
      .required('Informe uma senha.'),
    confirm: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'As senhas precisam ser iguais.'
    ),
  });

  async function handleSubmit(data) {
    setLoading(true);
    formRef.current.setErrors({});
    try {
      const validPassword = validatePassword(data.password);
      if (validPassword.length > 0) {
        formRef.current.setFieldError('password', 'Formato de senha inválido.');
        setLoading(false);
        return;
      }
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post(`/recover`, data);

      toast.success('Senha alterada com sucesso! Faça seu login.');
      history.push('/');

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((e) => {
          errorMessages[e.path] = e.message;
        });
        formRef.current.setErrors(errorMessages);
        return;
      }
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.error);
      } else
        toast.error('Falha ao processar sua solicitação. Tente novamente.');
    }
  }

  return (
    <>
      <div className="img">
        <img className="imgLogin" src={logo} alt="logo" />
      </div>
      <div className="signup">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Recuperação</h1>
          {/* <h3>
            Para sua segurança, você deve solicitar uma nova chave de ativação
            ao seu condomínio para que possa cadastrar uma nova senha.
          </h3> */}
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Chave de Ativação"
            name="key"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="E-mail"
            name="email"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Nova Senha"
            name="password"
            type="password"
          />
          <h3>
            Sua senha deve ter:
            <br />- No mínimo 6 e no máximo 20 caracteres;
            <br />- No mínimo uma letra maíuscula;
            <br />- No mínimo uma letra minúscula;
            <br />- No mínimo um número.
          </h3>
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Confirmar Nova Senha"
            name="confirm"
            type="password"
          />

          <button type="submit">
            {loading ? 'Carregando...' : 'Confirmar'}
          </button>
          <Link to="/">Voltar</Link>
        </Form>
      </div>
    </>
  );
}
