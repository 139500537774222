/* eslint-disable react/jsx-no-bind */
import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { TextField, Select, Switch } from 'unform-material-ui';
import { MenuItem } from '@material-ui/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FiArrowLeft } from 'react-icons/fi';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Container } from './styles';
import api from '~/services/api';
import goBack from '~/utils/goBack';
import history from '~/services/history';
import makeStyles from '~/styles/makeStyles';
import { networkErrorMessage } from '~/constants/message';

const useStyles = makeStyles;

export default function CondoEdit() {
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório.'),
    api_url: Yup.string().required('O endereço da API é obrigatório.'),
  });

  const [checked, setChecked] = React.useState(false);
  const [checkedQrResidents, setCheckedQrResidents] = React.useState(false);
  const [checkedQrGuests, setCheckedQrGuests] = React.useState(false);

  async function handleSubmit(data, { reset }) {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });
      await api.post(`condos/`, data);
      toast.success('Dados salvos com sucesso!');
      formRef.current.setErrors({});
      reset();
      history.push('/condominios');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((e) => {
          errorMessages[e.path] = e.message;
        });
        formRef.current.setErrors(errorMessages);
      } else {
        toast.error(networkErrorMessage);
      }
    }
  }

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleChangeQrResidents = () => {
    setCheckedQrResidents((prev) => !prev);
  };

  const handleChangeQrGuests = () => {
    setCheckedQrGuests((prev) => !prev);
  };

  const classes = useStyles();
  return (
    <Container>
      <FiArrowLeft className="icon" onClick={goBack} size={30} />

      <div className="bodyRegister">
        <h1>Condomínio</h1>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Nome"
            name="name"
          />

          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="http://192.168.0.1:3332"
            name="api_url"
          />

          <Select
            name="type"
            id="type"
            defaultValue="residential"
            className={classes.margin}
            label="Tipo"
          >
            <MenuItem value="residential">Residencial</MenuItem>
            <MenuItem value="commercial">Empresarial</MenuItem>
          </Select>

          <hr />
          <div className="switch">
            <p>Permite Liberação Direta</p>
            <FormControlLabel
              control={
                <Switch
                  name="allows_full_lib"
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                />
              }
            />
          </div>

          <div className="switch">
            <p>Permite Entrada com QR para Residentes</p>
            <FormControlLabel
              control={
                <Switch
                  name="allows_qr_residents"
                  checked={checkedQrResidents}
                  onChange={handleChangeQrResidents}
                  color="primary"
                />
              }
            />
          </div>

          <div className="switch">
            <p>Permite Entrada com QR para Visitantes</p>
            <FormControlLabel
              control={
                <Switch
                  name="allows_qr_guests"
                  checked={checkedQrGuests}
                  onChange={handleChangeQrGuests}
                  color="primary"
                />
              }
            />
          </div>
          <hr />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Instruções de Solicitação de Liberação"
            name="instruction_first"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Instruções de Liberação DIRETA"
            name="instruction_next"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Endereço"
            name="address"
          />
          <hr />

          {/* <Select
            name="status"
            id="status"
            defaultValue="online"
            className={classes.margin}
            label="Status"
          >
            <MenuItem value="offline">Offline</MenuItem>
            <MenuItem value="online">Online</MenuItem>
            <MenuItem value="deactivated">Desativado</MenuItem>
          </Select> */}

          <button type="submit">Cadastrar</button>
        </Form>
      </div>
    </Container>
  );
}
