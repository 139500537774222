import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '~/services/api';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import handleSearchSubmit from '~/utils/handleSearchSubmit';
import parseQuery from '~/utils/parseQuery';
import { networkErrorMessage } from '~/constants/message';

import { ListTableUser } from './styles';
import genericProfile from '~/assets/profile.jpg';

export default function Users() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);

  // Armazena quantidade de registros na API para controle de paginação
  const [results, setResults] = useState(0);

  // Armazena dados pesquisa, para controle de exibição de info na tela
  const [search, setSearch] = useState({
    status: false,
    content: '',
    count: 0,
  });

  // Determina quantos registros a API deve retornar
  const limit = 10;

  useEffect(() => {
    // Faz a requisição com parâmetros de pesquisa e paginação
    async function loadUser() {
      try {
        setLoading(true);
        const response = await api.get(
          `users${location.search}${location.search ? '&' : '?'}limit=${limit}`
        );
        // Caso seja uma requisição com pesquisa, seta informações necessárias
        if (parseQuery(location.search).query) {
          setSearch({
            status: true,
            count: response.headers['x-total-count'],
            content: parseQuery(location.search).query,
          });
        } else setSearch({ status: false });

        // Seta estado de quantos registros TOTAIS existem na API
        setResults(response.headers['x-total-count']);

        // Seta estado de resultados
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(networkErrorMessage);
      }
    }

    loadUser();
  }, [location.search]);

  function handleSearch(e) {
    handleSearchSubmit(e);
  }

  function castStatus(status) {
    if (status === 'new') return 'Novo';
    if (status === 'active') return 'Ativo';
    if (status === 'inactive') return 'Inativo';
    if (status === 'blocked') return 'Bloqueado';
    return '';
  }

  function castType(type) {
    if (type === 'super') return 'Super';
    if (type === 'admin') return 'Administrador';
    if (type === 'employee') return 'Funcionário';
    if (type === 'householder') return 'Residente';
    if (type === 'dweller') return 'Residente';
    if (type === 'guest') return 'Visitante';
    return '';
  }

  return (
    <ListTableUser>
      <div className="headerList">
        <Link to="/usuarios">
          <h1>Usuários</h1>
        </Link>
        <div className="searchParam">
          {search.status ? (
            <ul>
              {search.count} Resultados de pesquisa para:{' '}
              <span>{search.content}</span>.
            </ul>
          ) : (
            ''
          )}
        </div>
        <input
          type="text"
          placeholder="Pesquisa"
          onKeyDown={() => {
            handleSearch();
          }}
        />
      </div>
      {loading ? (
        <div className="load">
          <Loading />
        </div>
      ) : (
        <table>
          <tbody>
            <tr>
              <td className="first">Status</td>
              <td className="first">Nome</td>
              <td className="first">Email</td>
              <td className="first">Tipo</td>
              <td className="first">Condomínio</td>
              <td className="first">Ação</td>
            </tr>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="second">{castStatus(user.status)}</td>
                <td className="second">
                  <div className="imgWithText">
                    <div className="image_container">
                      <img
                        className="imgPerfil"
                        src={user.avatar ? user.avatar.url : genericProfile}
                        alt="logoBody"
                      />
                    </div>
                    {user.name}
                  </div>
                </td>
                <td className="second">{user.email}</td>
                <td className="second">{castType(user.role)}</td>
                <td className="second">{user.condo ? user.condo.name : ''}</td>
                <td className="second">
                  <Link to={`/usuarios/${user.id}`}>
                    <FiEdit size={22} className="icon-body" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="pagination">
        <Pagination
          count={Number(results)}
          maxResults={Number(limit)}
          page={Number(parseQuery(location.search).page)}
        />
      </div>
    </ListTableUser>
  );
}
