import React from 'react';
import { Error401 } from './styles';

export default function Error() {
  return (
    <Error401>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>403</h1>
      <span>Acesso negado.</span>
      <span>Você não tem permissão para acessar esta página.</span>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Error401>
  );
}
