import PasswordValidator from 'password-validator';

function validatePassword(password) {
  const schema = new PasswordValidator();

  schema
    .is()
    .min(6) // Minimum length 8

    .is()
    .max(20) // Maximum length 100

    .has()
    .uppercase() // Must have uppercase letters

    .has()
    .lowercase() // Must have lowercase letters

    .has()
    .digits() // Must have digits

    .has()
    .not()
    .spaces() // Should not have spaces

    .is()
    .not()
    .oneOf(['Passw0rd', 'Password123']); // Blacklist these values

  return schema.validate(password, { list: true });
}

export default validatePassword;
