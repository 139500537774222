import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import api from '~/services/api';
import Pagination from '~/components/Pagination';
import Loading from '~/components/Loading';
import handleSearchSubmit from '~/utils/handleSearchSubmit';
import parseQuery from '~/utils/parseQuery';
import { unity } from '~/constants/condoTypeStrings';
import { networkErrorMessage } from '~/constants/message';

import { ListAcesso } from './styles';

export default function FluxoAcesso() {
  const profile = useSelector((state) => state.user.profile);
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [report, setReport] = useState({
    records: [],
    lastAccess: [],
    count: 0,
    unidade: '',
  });

  // Armazena dados pesquisa, para controle de exibição de info na tela
  const [search, setSearch] = useState({
    status: false,
    content: '',
    count: 0,
  });

  // Determina quantos registros a API deve retornar
  const limit = 10;

  useEffect(() => {
    // Faz a requisição com parâmetros de pesquisa e paginação
    async function loadRecords() {
      try {
        setLoading(true);
        const response = await api.get(
          `accessreport${location.search}${
            location.search ? '&' : '?'
          }limit=${limit}&type=selfUnityGuests`
        );

        // Caso seja uma requisição com pesquisa, seta informações necessárias
        if (parseQuery(location.search).query) {
          setSearch({
            status: true,
            count: response.headers['x-total-count'],
            content: parseQuery(location.search).query,
          });
        } else setSearch({ status: false });

        // Seta estado de resultados
        setReport(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(networkErrorMessage);
      }
    }

    loadRecords();
  }, [location.search]);

  async function handleSearchElement(e) {
    handleSearchSubmit(e);
  }

  function setStatusColor(status) {
    if (status === 'Liberado') return '#008000';
    if (status === 'Negado') return '#cc0000';

    return '#333';
  }

  return (
    <ListAcesso>
      <div className="headerListFluxo">
        <div className="title">
          <Link to="/fluxodeacesso/meusacessos/visitantes">
            <h1>Minha {unity(profile.condo.type)} - Visitantes</h1>
          </Link>
          <h3>
            {unity(profile.condo.type)}: <span>{report.unidade}</span>
          </h3>
          {/* <h3>
            Último Acesso: <span>{report.lastAccess.timeDistance}</span> por:{' '}
            <span>{report.lastAccess.name}</span> Direção:
            <span> {report.lastAccess.direction}</span>
          </h3> */}
        </div>
        <div className="searchParamAcessSelf">
          {search.status ? (
            <ul>
              {search.count} Resultados de pesquisa para:{' '}
              <span>{search.content}</span>.
            </ul>
          ) : (
            ''
          )}
        </div>
        <input
          type="text"
          placeholder="Pesquisa"
          onKeyDown={() => {
            handleSearchElement();
          }}
        />
      </div>
      {loading ? (
        <div className="load">
          <Loading />
        </div>
      ) : (
        <table>
          <tbody>
            <tr>
              <td className="titleColumn">Data/Hora</td>
              <td className="titleColumn">Nome</td>
              <td className="titleColumn">Classificação</td>
              <td className="titleColumn">Local</td>
              <td className="titleColumn">Status</td>
              <td className="titleColumn">Direção</td>
              <td className="titleColumn">Método de Acesso</td>
            </tr>

            {report.records.map((r) => (
              <tr
                key={r.id}
                style={
                  {
                    // backgroundColor: r.granted ? '#fff' : '#fff2f2',
                  }
                }
              >
                <td>{r.date}</td>
                <td>{r.name}</td>
                <td>{r.type}</td>
                <td>{r.device}</td>
                <td style={{ color: setStatusColor(r.status) }}>{r.status}</td>
                <td>{r.direction}</td>
                <td>{r.method}</td>
              </tr>
            ))}

            {/* {report.records.map((r) => (
            <tr
              key={r.id}
              style={{
                backgroundColor: r.granted ? '#fff' : '#fff2f2',
              }}
            >
              <td>{r.date}</td>
              <td>{r.name}</td>
              <td>{r.type}</td>
              <td>{r.device}</td>
              <td>{r.status}</td>
              <td>{r.direction}</td>
              <td>{r.method}</td>
            </tr>
          ))} */}
          </tbody>
        </table>
      )}

      <div className="pagination">
        <Pagination
          count={Number(report.count)}
          maxResults={Number(limit)}
          page={Number(parseQuery(location.search).page)}
        />
      </div>
    </ListAcesso>
  );
}
