export function unity(type) {
  if (type === 'residential') return 'Unidade';
  if (type === 'commercial') return 'Empresa';

  return null;
}

export function dweller(type) {
  if (type === 'residential') return 'Morador';
  if (type === 'commercial') return 'Colaborador';

  return null;
}

export function dwellerPlural(type) {
  if (type === 'residential') return 'Moradores';
  if (type === 'commercial') return 'Colaboradores';

  return null;
}
