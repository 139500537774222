/* eslint-disable react/jsx-no-bind */
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { TextField } from 'unform-material-ui';

import validatePassword from '../../utils/validatePassword';
import { signUpRequest } from '~/store/modules/auth/actions';

import logo from '../../assets/splash.png';
import makeStyles from '~/styles/makeStyles';

const useStyles = makeStyles;

export default function SignUp() {
  const classes = useStyles();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    name: Yup.string().required('Informe seu nome.'),
    email: Yup.string()
      .email('Insira um e-mail válido.')
      .required('Informe seu e-mail.'),
    password: Yup.string()
      .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
      .required('Informe uma senha.'),
    yourpassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'As senhas precisam ser iguais.'
    ),
  });

  async function handleSubmit(data) {
    try {
      const validPassword = validatePassword(data.password);
      if (validPassword.length > 0) {
        formRef.current.setFieldError('password', 'Formato de senha inválido.');
        return;
      }

      await schema.validate(data, {
        abortEarly: false,
      });

      const { name, password, email } = data;

      dispatch(signUpRequest(name, email, password));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((e) => {
          errorMessages[e.path] = e.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  return (
    <>
      <div className="img">
        <img className="imgLogin" src={logo} alt="logo" />
      </div>
      <div className="signup">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Registro</h1>
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Nome"
            name="name"
          />

          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="E-mail"
            name="email"
          />

          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Senha"
            name="password"
            type="password"
          />
          <h3>
            Sua senha deve ter:
            <br />- No mínimo 6 e no máximo 20 caracteres;
            <br />- No mínimo uma letra maíuscula;
            <br />- No mínimo uma letra minúscula;
            <br />- No mínimo um número.
          </h3>
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Confirmar Senha"
            name="yourpassword"
            type="password"
          />
          <button type="submit">Criar conta</button>
          <Link to="/">Já sou cadastrado</Link>
        </Form>
      </div>
    </>
  );
}
