import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import Resizer from 'react-image-file-resizer';

import api from '~/services/api';
import genericProfile from '~/assets/profile.jpg';

import { Container } from './styles';

function AvatarInput() {
  const { defaultValue, registerField } = useField('avatar');

  const [file, setFile] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.url);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'avatar_id',
        ref: ref.current,
        path: 'dataset.file',
      });
    }
  }, [ref, registerField]);

  // Redimensiona imagem pois é desnecessário manter tamanho/qualidade original no servidor
  const resizeFile = (fileB) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        fileB,
        600,
        400,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'blob',
        200,
        200
      );
    });

  async function handleChange(e) {
    const fileA = e.target.files[0];
    const image = await resizeFile(fileA);

    const data = new FormData();
    data.append('file', image, 'image.jpg');

    const response = await api.post('files', data, {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const { id, url } = response.data;

    setFile(id);
    setPreview(url);
  }

  return (
    <Container>
      <label htmlFor="avatar">
        <div className="image_container">
          <img src={preview || genericProfile} alt="" />
        </div>

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
          ref={ref}
        />
      </label>
    </Container>
  );
}

export default AvatarInput;
