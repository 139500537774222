import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiTrash2 } from 'react-icons/fi';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Loading from '~/components/Loading';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import handleSearchSubmit from '~/utils/handleSearchSubmit';
import parseQuery from '~/utils/parseQuery';
import { networkErrorMessage } from '~/constants/message';

import { ListTableUser } from './styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function Users() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [permit, setPermit] = useState({
    records: [],
    count: 0,
    unidade: '',
  });
  // Armazena dados pesquisa, para controle de exibição de info na tela
  const [search, setSearch] = useState({
    status: false,
    content: '',
    count: 0,
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (record) => {
    setOpen(true);
    setSelectedRecord(record);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Determina quantos registros a API deve retornar
  const limit = 10;

  useEffect(() => {
    // Faz a requisição com parâmetros de pesquisa e paginação
    async function loadRecords() {
      try {
        setLoading(true);
        const response = await api.get(
          `accesspermit${location.search}${
            location.search ? '&' : '?'
          }limit=${limit}&type=self`
        );
        setLoading(false);
        // Caso seja uma requisição com pesquisa, seta informações necessárias
        if (parseQuery(location.search).query) {
          setSearch({
            status: true,
            count: response.headers['x-total-count'],
            content: parseQuery(location.search).query,
          });
        } else setSearch({ status: false });

        // Seta estado de resultados
        setPermit(response.data);
      } catch (error) {
        toast.error(networkErrorMessage);
        setLoading(false);
      }
    }

    loadRecords();
  }, [location.search]);

  async function handleSearchElement(e) {
    handleSearchSubmit(e);
  }

  async function handlePermitCancel() {
    handleClose();
    if (selectedRecord.statusID !== 0) {
      toast.warning('Somente liberações pendentes podem ser canceladas.');
      return;
    }

    setLoading(true);

    try {
      await api.put(`accesspermit/${selectedRecord.id}`);
      toast.success('Liberação cancelada com sucesso!');
    } catch (error) {
      toast.error('Não foi possível cancelar a liberação. Tente novamente.');
    }

    // Atualiza a página (precisa de otimização)
    try {
      setLoading(true);
      const response = await api.get(
        `accesspermit${location.search}${
          location.search ? '&' : '?'
        }limit=${limit}&type=self`
      );
      setLoading(false);
      // Caso seja uma requisição com pesquisa, seta informações necessárias
      if (parseQuery(location.search).query) {
        setSearch({
          status: true,
          count: response.headers['x-total-count'],
          content: parseQuery(location.search).query,
        });
      } else setSearch({ status: false });

      // Seta estado de resultados
      setPermit(response.data);
    } catch (error) {
      toast.error('Não foi possível recarregar os dados. Tente novamente.');
    }

    setLoading(false);
  }

  const Dial = () => (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Cancelamento</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deseja realmente cancelar a liberação selecionada?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Não
        </Button>
        <Button onClick={() => handlePermitCancel()} color="primary" autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );

  function setStatusPermitColor(id) {
    if (id === 0) return '#00507c';
    if (id === 1) return '#ffc266';
    if (id === 2) return '#008000';
    if (id === 3) return '#cc0000';

    return '#333';
  }

  return (
    <ListTableUser>
      <Dial />
      <div className="headerList">
        <div className="title">
          <Link to="/liberacao">
            <h1>Liberações</h1>
          </Link>
          <h3>
            Unidade: <span>{permit.unidade}</span>
          </h3>
        </div>
        <div className="searchParam">
          {search.status ? (
            <ul>
              {search.count} Resultados de pesquisa para:{' '}
              <span>{search.content}</span>.
            </ul>
          ) : (
            ''
          )}
        </div>
        <input
          type="text"
          placeholder="Pesquisa"
          onKeyDown={() => {
            handleSearchElement();
          }}
        />
      </div>
      {loading ? (
        <div className="load">
          <Loading />
        </div>
      ) : (
        <table>
          <tbody>
            <tr>
              <td className="titleColumn">Nome</td>
              <td className="titleColumn">Tipo</td>
              <td className="titleColumn">Data</td>
              <td className="titleColumn">Status</td>
              <td className="titleColumn">Ação</td>
            </tr>
            {permit.records.map((r) => (
              <tr
                key={r.id}
                style={
                  {
                    // backgroundColor: r.granted ? '#fff' : '#fff2f2',
                  }
                }
              >
                <td>{r.name}</td>
                <td>{r.type}</td>
                <td>{r.date}</td>
                <td style={{ color: setStatusPermitColor(r.statusID) }}>
                  {r.status}
                </td>
                <td>
                  <Button onClick={() => handleClickOpen(r)}>
                    <FiTrash2 size={22} className="icon-body" color="#ebb0b0" />
                  </Button>
                  <div />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="pagination">
        <Link to="/liberacao/cadastro">
          <button type="button" className="adicionarNovo">
            Cadastrar
          </button>
        </Link>
        <div className="pagExport">
          <Pagination
            count={Number(permit.count)}
            maxResults={Number(limit)}
            page={Number(parseQuery(location.search).page)}
          />
        </div>
      </div>
    </ListTableUser>
  );
}
