import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FiEdit, FiRefreshCw } from 'react-icons/fi';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';

import Loading from '~/components/Loading';

import api from '~/services/api';
import Pagination from '~/components/Pagination';
import handleSearchSubmit from '~/utils/handleSearchSubmit';
import parseQuery from '~/utils/parseQuery';
import { networkErrorMessage } from '~/constants/message';

import { ListCondominio } from './styles';

export default function Condominio() {
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [condominios, setCondominios] = useState([]);

  // Armazena quantidade de registros na API para controle de paginação
  const [results, setResults] = useState(0);

  // Armazena dados pesquisa, para controle de exibição de info na tela
  const [search, setSearch] = useState({
    status: false,
    content: '',
    count: 0,
  });

  // Determina quantos registros a API deve retornar
  const limit = 20;

  useEffect(() => {
    async function loadCondominio() {
      try {
        setLoading(true);
        const response = await api.get(
          `condos${location.search}${location.search ? '&' : '?'}limit=${limit}`
        );
        // Caso seja uma requisição com pesquisa, seta informações necessárias
        if (parseQuery(location.search).query) {
          setSearch({
            status: true,
            count: response.headers['x-total-count'],
            content: parseQuery(location.search).query,
          });
        } else setSearch({ status: false });

        // Seta estado de quantos registros TOTAIS existem na API
        setResults(response.headers['x-total-count']);

        // Seta estado de resultados
        setCondominios(response.data);
        setLoading(false);
      } catch (error) {
        toast.error(networkErrorMessage);
        setLoading(false);
      }
    }

    loadCondominio();
  }, [location.search]);

  // Pesquisa
  async function handleSearchElement(e) {
    handleSearchSubmit(e);
  }

  // function castStatus(status) {
  //   if (status === 'online') return 'Online';
  //   if (status === 'offline') return 'Offline';
  //   return '';
  // }

  async function handleStatus(id, name) {
    try {
      await api.get(`status/${id}`);
      toast.success(`${name} ONLINE.`);
    } catch (error) {
      toast.error(`${name} OFFLINE.`);
    }
    return id;
  }

  function castType(type) {
    if (type === 'residential') return 'Residencial';
    if (type === 'commercial') return 'Empresarial';
    return '';
  }

  return (
    <ListCondominio>
      <div className="headerlistcondominio">
        <Link to="/condominios">
          <h1>Condomínios</h1>
        </Link>
        <div className="searchParam">
          {search.status ? (
            <ul>
              {search.count} Resultados de pesquisa para:{' '}
              <span>{search.content}</span>.
            </ul>
          ) : (
            ''
          )}
        </div>
        <input
          type="text"
          placeholder="Pesquisa"
          onKeyDown={() => {
            handleSearchElement();
          }}
        />
      </div>
      {loading ? (
        <div className="load">
          <Loading />
        </div>
      ) : (
        <table>
          <tbody>
            <tr>
              <td className="titleColumn">ID</td>
              <td className="titleColumn">Status</td>
              <td className="titleColumn">Nome</td>
              <td className="titleColumn">Tipo</td>
              <td className="titleColumn">Endereço da API</td>
              <td className="titleColumn">Ação</td>
            </tr>
            {condominios.map((condominio) => (
              <tr key={condominio.id}>
                <td>{condominio.id}</td>
                <td>
                  <Button
                    onClick={() => handleStatus(condominio.id, condominio.name)}
                  >
                    <FiRefreshCw
                      size={22}
                      className="icon-body"
                      color="#00507c"
                    />
                  </Button>
                </td>
                <td>
                  <div className="nameCondominium">{condominio.name}</div>
                </td>
                <td>{castType(condominio.type)}</td>
                <td>{condominio.api_url}</td>
                <td>
                  <Link to={`/condominios/${condominio.id}`}>
                    <FiEdit size={22} className="icon-body" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="pagination">
        <Link to="/condominios/cadastro">
          <button type="button" className="adicionarNovo">
            Cadastrar
          </button>
        </Link>
        <div className="pagExport">
          <Pagination
            count={Number(results)}
            maxResults={Number(limit)}
            page={Number(parseQuery(location.search).page)}
          />
        </div>
      </div>
    </ListCondominio>
  );
}
