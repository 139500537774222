import axios from 'axios';
import LocalStorageService from '~/services/localStorageService';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
});

const localStorageService = LocalStorageService.getService();

// Seta o Access Token em todas as requisições
api.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // config.headers.extid = 'web';
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Intercepta todas as respostas. Caso tenha retorno 401, tenta renovar o token
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Caso seja 403, redireciona para página de erro
    if (error.response.status === 403) {
      window.location.href = '/erro';
    }

    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url === 'auth/refresh'
    ) {
      localStorage.clear();
      window.location.href = '/';
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== 'sessions'
    ) {
      originalRequest._retry = true;
      const accessToken = localStorageService.getAccessToken();
      const refreshToken = localStorageService.getRefreshToken();
      return (
        api
          .post('auth/refresh', {
            accessToken,
            refreshToken,
          })
          // eslint-disable-next-line consistent-return
          .then((res) => {
            if (res.status === 200) {
              localStorageService.setToken(res.data);
              api.defaults.headers.common.Authorization = `Bearer ${localStorageService.getAccessToken()}`;
              return api(originalRequest);
            }
          })
      );
    }
    return Promise.reject(error);
  }
);

export default api;
