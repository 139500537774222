import React from 'react';

// import { Container } from './styles';

export default function Dashboard() {
  return (
    <>
      <br />
      <br /> <br />
      <br />
      <h1>Suporte</h1>
      <span>
        Para mais informações entre em contato com o responsável do condomínio.
      </span>
      <span>Telefone: (12)3456-7890</span>
      <span>Responsável: Leandro</span>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
