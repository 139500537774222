/* eslint-disable react/jsx-no-bind */
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { TextField, Select } from 'unform-material-ui';
import { MenuItem } from '@material-ui/core';
import { toast } from 'react-toastify';

import { FiArrowLeft } from 'react-icons/fi';
import api from '~/services/api';
import history from '~/services/history';
import { networkErrorMessage } from '~/constants/message';

import { CadastroLiberacao } from './styles';
import makeStyles from '~/styles/makeStyles';

const useStyles = makeStyles;

export default function EditUser() {
  const formRef = useRef(null);
  const classes = useStyles();

  const schema = Yup.object().shape({
    name: Yup.string().required('Informe o nome completo do visitante.'),
    doc: Yup.number()
      .typeError('O RG deve conter apenas números.')
      .min(5, 'O RG deve conter no mínimo 5 dígitos.')
      .required('Informe o RG do visitante.')
      .nullable(true),
    initialDate: Yup.date().typeError('Insira uma data válida.'),
  });

  async function handleSubmit(data, { reset }) {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });
      const response = await api.post(`/accesspermit`, data);
      if (response.data.error && response.data.error.alreadyHas) {
        toast.warning(
          'Este visitante já possui uma solicitação aprovada para este dia.'
        );
        return;
      }
      toast.success('Solicitação cadastrada com sucesso.');
      formRef.current.setErrors({});
      reset();
      history.push('/liberacao');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((e) => {
          errorMessages[e.path] = e.message;
        });
        formRef.current.setErrors(errorMessages);
      } else {
        toast.error(networkErrorMessage);
      }
    }
  }

  function goBack() {
    history.goBack();
  }

  return (
    <CadastroLiberacao>
      <FiArrowLeft className="icon" onClick={goBack} size={30} />
      <div className="bodyRegister">
        <h1>Liberação</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Nome"
            name="name"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="RG"
            name="doc"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Telefone"
            name="phone"
          />
          <Select
            name="type"
            id="type"
            defaultValue="1"
            className={classes.margin}
            label="Tipo"
          >
            <MenuItem value="1">Visita</MenuItem>
            <MenuItem value="2">Serviços</MenuItem>
          </Select>
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Empresa (caso Serviços)"
            name="company"
          />
          <TextField
            InputProps={{ className: classes.textField }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            label="Data da Liberação"
            name="initialDate"
            type="date"
            defaultValue={new Date()}
          />
          <button type="submit">Cadastrar</button>
        </Form>
      </div>
    </CadastroLiberacao>
  );
}
