import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';
import Activation from '~/pages/Activation';
import Recover from '~/pages/Recover';

import Dashboard from '~/pages/Dashboard';

import User from '~/pages/Users';
import UserEdit from '~/pages/Users/Edit/index';

import Condo from '~/pages/Condos';
import CondoEdit from '~/pages/Condos/Edit';
import CondoRegister from '~/pages/Condos/Register';

import Profile from '~/pages/Profile';

import AccessReportSelf from '~/pages/AccessReport/Self';
import AccessReportSelfDweller from '~/pages/AccessReport/Self/Dweller';
import AccessReportSelfGuest from '~/pages/AccessReport/Self/Guest';
import AccessReportGeneralGuest from '~/pages/AccessReport/General/Guest';
import AccessReportGeneralDweller from '~/pages/AccessReport/General/Dweller';

import AccessPermit from '~/pages/AccessPermit';
import AccessPermitRegister from '~/pages/AccessPermit/Register';

import Configuration from '~/pages/Configuration';

import Contact from '~/pages/Contact';

import Error from '~/pages/Error';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/registro" component={SignUp} />
      <Route path="/recuperacao" component={Recover} />
      <Route path="/ativacao" component={Activation} isPrivate isActivation />
      <Route path="/erro" component={Error} isPrivate />

      {/* rotas privadas */}
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/perfil" component={Profile} isPrivate />
      <Route exact path="/usuarios" component={User} isPrivate />
      <Route path="/usuarios/:id" component={UserEdit} isPrivate />
      <Route exact path="/condominios" component={Condo} isPrivate />
      <Route
        exact
        path="/condominios/cadastro"
        component={CondoRegister}
        isPrivate
      />
      <Route path="/condominios/:id" component={CondoEdit} isPrivate />

      <Route
        exact
        path="/fluxodeacesso/meusacessos"
        component={AccessReportSelf}
        isPrivate
      />
      <Route
        exact
        path="/fluxodeacesso/meusacessos/residentes"
        component={AccessReportSelfDweller}
        isPrivate
      />
      <Route
        exact
        path="/fluxodeacesso/meusacessos/visitantes"
        component={AccessReportSelfGuest}
        isPrivate
      />

      <Route
        exact
        path="/fluxodeacesso/geral/residentes"
        component={AccessReportGeneralDweller}
        isPrivate
      />
      <Route
        exact
        path="/fluxodeacesso/geral/visitantes"
        component={AccessReportGeneralGuest}
        isPrivate
      />

      <Route exact path="/liberacao" component={AccessPermit} isPrivate />
      <Route
        exact
        path="/liberacao/cadastro"
        component={AccessPermitRegister}
        isPrivate
      />

      <Route path="/suporte" component={Contact} isPrivate />
      <Route path="/configuration" component={Configuration} isPrivate />

      {/* rotas de erro */}
      <Route path="*" component={() => <h1>404</h1>} />
    </Switch>
  );
}
