/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FiEye,
  FiUsers,
  FiUser,
  FiLogOut,
  FiRefreshCcw,
  FiHome,
  FiChevronsDown,
  FiFileText,
} from 'react-icons/fi';

import { signOut } from '~/store/modules/auth/actions';
import { unity, dwellerPlural } from '~/constants/condoTypeStrings';
import { CadastroUserAdm } from '~/components/Sidebar/styles';

export default function Sidebar() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const [hidden, setHidden] = useState(false);

  function handleSignOut() {
    dispatch(signOut());
  }

  function handleClick() {
    if (!hidden) {
      setHidden(true);
    } else setHidden(false);
  }

  return (
    <div className="container">
      <CadastroUserAdm>
        <div className="border">
          <Link to="/dashboard" className="link">
            <FiEye size={16} className="icon-menu" />
            <span>Principal</span>
          </Link>
        </div>

        {profile.role === 'super' && (
          <div className="border">
            <Link to="/condominios" className="link">
              <FiHome size={16} className="icon-menu" />
              <span>Condomínios</span>
            </Link>
          </div>
        )}

        <div className="sub">
          {profile.role !== 'guest' && (
            <div className="borderFluxoAcesso">
              <div className="link">
                <FiRefreshCcw size={16} className="icon-menu" />
                <input type="checkbox" id="products" onClick={handleClick} />
                <label htmlFor="products">
                  Fluxo de Acesso
                  <FiChevronsDown size={16} className="icon-menu-down-title" />
                </label>
              </div>
            </div>
          )}
          {hidden ? (
            <ul className="submenu">
              {(profile.role === 'super' ||
                profile.role === 'householder' ||
                profile.role === 'dweller') && (
                <li>
                  <Link to="/fluxodeacesso/meusacessos" className="subMenuLink">
                    <FiUser size={16} className="icon-menu" />
                    <span>Meus Acessos</span>
                  </Link>
                </li>
              )}
              {(profile.role === 'super' || profile.role === 'householder') && (
                <li>
                  <FiHome size={16} className="icon-menu" />
                  <label htmlFor="submenuinputunidade">
                    Minha {unity(profile.condo.type)}
                    <FiChevronsDown size={16} className="icon-menu-down" />
                  </label>

                  <input
                    type="checkbox"
                    name="submenuinput"
                    id="submenuinputunidade"
                  />
                  <ul className="submenuunidade">
                    <div className="borderForSubMenu">
                      <li>
                        <Link
                          to="/fluxodeacesso/meusacessos/residentes"
                          className="subMenuLink"
                        >
                          <FiUser size={16} className="icon-menu" />
                          <span>{dwellerPlural(profile.condo.type)}</span>
                        </Link>
                      </li>
                    </div>
                    <div className="borderForSubMenu">
                      <li>
                        <Link
                          to="/fluxodeacesso/meusacessos/visitantes"
                          className="subMenuLink"
                        >
                          <FiUsers size={16} className="icon-menu" />
                          <span>Visitantes</span>
                        </Link>
                      </li>
                    </div>
                  </ul>
                </li>
              )}
              {profile.role !== 'guest' &&
                profile.role !== 'dweller' &&
                profile.role !== 'householder' && (
                  <li>
                    <FiUsers size={16} className="icon-menu" />
                    <label htmlFor="submenuinputgeral">
                      Geral
                      <FiChevronsDown size={16} className="icon-menu-down" />
                    </label>

                    <input
                      type="checkbox"
                      name="submenuinput"
                      id="submenuinputgeral"
                    />
                    <ul className="submenuunidade">
                      <div className="borderForSubMenu">
                        <li>
                          <Link
                            to="/fluxodeacesso/geral/residentes"
                            className="subMenuLink"
                          >
                            <FiUser size={16} className="icon-menu" />
                            <span>{dwellerPlural(profile.condo.type)}</span>
                          </Link>
                        </li>
                      </div>
                      <div className="borderForSubMenu">
                        <li>
                          <Link
                            to="/fluxodeacesso/geral/visitantes"
                            className="subMenuLink"
                          >
                            <FiUsers size={16} className="icon-menu" />
                            <span>Visitantes</span>
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </li>
                )}
            </ul>
          ) : (
            ''
          )}
        </div>

        {(profile.role === 'householder' || profile.role === 'super') && (
          <div className="border">
            <Link to="/liberacao" className="link">
              <FiFileText size={16} className="icon-menu" />
              <span>Liberação</span>
            </Link>
          </div>
        )}

        {(profile.role === 'super' || profile.role === 'admin') && (
          <div className="border">
            <Link to="/usuarios" className="link">
              <FiUsers size={16} className="icon-menu" />
              <span>Usuários</span>
            </Link>
          </div>
        )}

        {/* {profile.role === 'super' && (
          <div className="border">
            <Link to="/suporte" className="link">
              <FiMessageCircle size={16} className="icon-menu" />
              <span>Suporte</span>
            </Link>
          </div>
        )} */}

        {/* {profile.role === 'super' && (
          <div className="border">
            <Link to="/configuration" className="link">
              <FiSettings size={16} className="icon-menu" />
              <span>Configurações</span>
            </Link>
          </div>
        )} */}

        <div className="border">
          <button type="button" className="link" onClick={handleSignOut}>
            <FiLogOut size={16} className="icon-menu" />
            <span>Sair</span>
          </button>
        </div>
      </CadastroUserAdm>
    </div>
  );
}
