import styled from 'styled-components';
import { darken } from 'polished';
import search from '~/assets/search.png';

export const ListTableUser = styled.div`
  width: 100%;
  height: auto;

  table {
    margin-top: 20px;
    width: 100%;
    border-spacing: 0 0px;
  }

  tr {
    height: 55px;
  }

  .title {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  h3 {
    white-space: nowrap;
    margin-left: 20px;
    font-size: 14px;
  }

  td {
    padding-left: 20px;
    margin-top: 15px;
    text-align: left;
    font-family: 'poppins', sans-serif;
    color: #888888;
    font-size: 14px;
    vertical-align: middle;
  }

  .titleColumn {
    font-size: 16px;
    font-weight: bold;
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 10px 0 20px;
  }

  h1 {
    margin-left: 20px;
    font-size: 28px;
    font-family: 'poppins';
    font-weight: bold;
    color: #00507c;

    &:hover {
      font-size: 29px;
      opacity: 0.8;
    }
  }

  tr + tr:hover {
    background-color: #e6f2ff;
  }

  span {
    color: #00507c;
    font-weight: bold;
  }

  .headerList {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input {
    border-radius: 100px;
    border: 1px solid #cccccc;
    width: 400px;
    height: 30px;
    margin-left: auto;
    text-align: left;
    text-indent: 10px;
    padding-right: 10px;
    font-family: 'poppins', sans-serif;
    margin-right: 20px;
    padding: 17px;
    padding-left: 35px;
    background-repeat: no-repeat;
    background-image: url(${search});
    background-position: left center;
    outline: 0;
    background-size: 5ex;
    color: black;
    &::placeholder {
      color: #858585;
    }
  }

  .pagination {
    margin-top: 25px;
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .adicionarNovo {
      margin-left: 20px;
      border-radius: 4px;
      width: 200px;
      height: 44px;
      font-size: 14px;
      background-color: #00507c;
      font-family: 'poppins', sans-serif;
      border: none;
      color: white;
      transition: 0.5s;

      &:hover {
        border-radius: 10px;
        background: ${darken(0.05, '#00507c')};
      }
    }
    .pagExport {
      position: relative;
      margin: auto;
      left: -110px;
    }
  }
`;
